<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="space-between">
        <el-col :md="18">
          <base-input label="Nome:" v-model="role_.name"> </base-input>
        </el-col>
        <el-checkbox
          class="checkbox-margin-auto"
          v-model="role_.is_commissioned"
          >É comissionado</el-checkbox
        >
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["role", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      role_: {},
      isLoadingSave: false,
    };
  },
  watch: {
    role(v) {
      this.role_ = v || {};
    },
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar função" : "Cadastrar função";
    },
    isNew() {
      return !this.role;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateRole() : this.createRole();
    },
    createRole() {
      fetch(`${this.$store.state.apiUrl}roles`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.role_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.role_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar a função",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateRole() {
      fetch(`${this.$store.state.apiUrl}roles/${this.role_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.role_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("should-update");
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar a função",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "RoleModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>