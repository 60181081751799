<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Funções na empresa</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openRoleModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="roles"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column
        prop="is_commissioned"
        label="comissão"
        :formatter="
          (r) => (r?.is_commissioned ? 'Comissionado' : 'Não comissionado')
        "
      >
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                @confirm="updateRoleStatus(cat?.row, !cat?.row?.is_active)"
                :title="
                  cat?.row?.is_active ? 'Desativar cargo?' : 'Ativar cargo?'
                "
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    :type="cat.row.is_active ? 'primary' : 'danger'"
                    circle
                    ><i
                      :class="{
                        'el-icon-lock': !cat.row.is_active,
                        'el-icon-unlock': cat.row.is_active,
                      }"
                    ></i
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openRoleModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteRole(cat.row)"
                title="
              Remover função?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <role-modal
      :showModal="showRoleModal"
      :role="role"
      @close-modal="showRoleModal = false"
      @should-update="fetchRoles"
    ></role-modal>
  </el-card>
</template>

<script>
import RoleModal from "./modals/RoleModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "RolesPage",
  components: { RoleModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    roles: null,
    role: null,
    showRoleModal: false,
  }),
  mounted() {
    this.fetchRoles();
  },
  methods: {
    deleteRole(role) {
      if (role)
        fetch(`${this.$store.state.apiUrl}roles/${role.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.text();
          })
          .then(() => {
            this.fetchRoles();
            this.$notify({
              title: "Cargo removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch((e) => {
            this.$notify({
              title: "Não foi possível remover a função",
              type: "error",
              message: e.message,
              position: "bottom-right",
            });
          });
    },
    openRoleModal(e) {
      (this.role = e), (this.showRoleModal = true);
    },
    updateRole(role) {
      if (role)
        fetch(`${this.$store.state.apiUrl}roles/${role.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...role }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.$emit("should-update");
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
              this.$emit("should-update");
              this.$emit("close-modal");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar a função",
                message: e.message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
            this.shouldShow = false;
          });
    },
    updateRoleStatus(role, status) {
      role.is_active = status;
      this.updateRole(role);
    },
    fetchRoles() {
      fetch(`${this.$store.state.apiUrl}roles`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.roles = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>